import { HTMLProps } from "react";

export default function Logo(props: HTMLProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 312" fill="none">
      <path
        d="m270 0 4 1c.05 4.425.086 8.85.11 13.275.01 1.498.023 2.996.04 4.494.24 20.956-1.883 40.712-16.15 57.231-17.355 14.851-38.676 17.376-60.5 20.25-1.999.27-3.998.54-5.996.813-4.834.655-9.668 1.299-14.504 1.937l.006 2.525c.044 20.317.077 40.633.098 60.95.01 9.826.024 19.65.047 29.476.02 8.568.033 17.136.037 25.704.003 4.533.009 9.066.023 13.598.014 4.276.018 8.551.015 12.827 0 1.56.005 3.12.012 4.68.088 18.496-1.783 33.87-14.238 48.24-10.842 10.573-23.813 15.4-38.79 15.37-13.604-.376-26.834-6.463-36.21-16.37-9.21-11.239-13.54-23.81-12.938-38.375 1.536-14.3 7.673-26.422 18.493-35.852C106.105 212.586 117.462 211.11 133 210V107c-4.33-1.083-7.608-.708-11.927-.075l-2.411.334c-2.635.368-5.268.748-7.9 1.128l-5.525.782a4729.16 4729.16 0 0 0-11.603 1.663c-4.916.71-9.834 1.404-14.753 2.094a3952.69 3952.69 0 0 0-11.43 1.63c-1.81.258-3.62.515-5.43.768C38.833 118.58 20.537 124.11 2 139-.942 136.058.688 126.816.685 122.85.911 104.018 7.942 90.447 21 77l1.86-2.082C38.6 57.866 61.36 51.576 83.58 48.59c1.505-.21 3.01-.421 4.514-.633 3.21-.45 6.421-.893 9.633-1.332 5.094-.696 10.185-1.408 15.277-2.121A19345.7 19345.7 0 0 1 145.437 40c11.675-1.614 23.35-3.232 35.02-4.868 5.047-.706 10.094-1.404 15.142-2.094 3.157-.433 6.313-.875 9.468-1.318 1.446-.201 2.893-.4 4.34-.595 12.561-1.694 25.562-3.87 36.905-9.813l2.008-1.03C257.422 15.401 264.274 8.541 270 0Z"
        fill="#F50"
      />
      <path
        d="M268 91c5.007 1.919 6.159 5.426 8.352 10.078 4.933 11.422 8.7 23.274 10.773 35.547l.428 2.422c.78 5.305.78 5.305-.514 7.824-3.113 1.724-6.372 2.659-9.789 3.566l-2.363.642c-10.15 2.649-20.51 4.426-30.887 5.921l-2.205.326c-8.338 1.032-16.77.824-25.157.804-2.725-.005-5.45 0-8.175.007-1.729 0-3.457-.002-5.186-.004l-2.474.006c-5.688-.024-5.688-.024-6.803-1.139a56.912 56.912 0 0 1-.114-4.06v-2.61l.016-2.834.005-2.888c.005-3.057.018-6.114.03-9.17A8189.481 8189.481 0 0 1 194 114l3.035-.471c3.806-.595 7.61-1.204 11.412-1.818 1.63-.26 3.26-.518 4.89-.77 8.763-1.361 17.357-2.94 25.878-5.43l2.04-.594C250.942 101.948 260.03 97.262 268 91ZM105.875 166.813l2.651-.04 2.466-.003 2.199-.013C115 167 115 167 117 169c.243 3.057.243 3.057.23 6.941v2.07c-.004 1.443-.012 2.886-.024 4.33-.018 2.216-.02 4.432-.02 6.65-.039 10.862-.039 10.862-1.186 12.009-1.973.182-3.951.3-5.93.395l-3.809.2-2.042.106c-30.525 1.63-61.083 6.002-89.012 19.072C12 222 12 222 9.757 222.16c-3.04-2.007-3.76-5.412-4.882-8.722l-.762-2.096C-1.197 196.427-1.197 196.427 0 192c27.47-21.747 72.492-24.895 105.875-25.188ZM288 164c.324 8.25.078 16.106-1.313 24.25l-.356 2.2c-.706 3.822-1.283 6.111-4.331 8.55-1.948.752-1.948.752-4.191 1.281l-2.57.609-2.801.61-2.967.647c-18.23 3.807-36.263 5.119-54.846 5.103l-2.424-.002a999.048 999.048 0 0 1-6.85-.045l-2.073-.013c-3.33-.05-6.013-.374-9.278-1.19v-33l8.746-.113c27.46-.411 53.556-1.66 80.29-8.476C285 164 285 164 288 164ZM116 124c1 1 1 1 1.114 3.592l-.016 3.412-.01 3.682-.025 3.876-.014 3.89c-.012 3.182-.028 6.365-.049 9.548l-2.74.136c-37.045 1.885-75.471 4.526-109.33 21.205C3 174 3 174 0 173c.487-9.249 7.523-17.82 14-24 18.33-15.253 41.485-17.626 64.23-20.72 3.922-.534 7.842-1.082 11.762-1.632l7.516-1.039 3.532-.492 3.3-.446 2.882-.394A62.956 62.956 0 0 1 116 124Z"
        fill="#8300FF"
      />
      <path
        d="M277 217c-1.751 11.733-10.072 22.695-17 32l-1.66 2.297c-3.32 4.285-5.821 5.827-11.34 6.703-9.067.94-18.108 1.158-27.213 1.13-2.115-.005-4.23 0-6.344.007-1.373 0-2.746-.002-4.119-.004l-3.685-.004c-3.895-.138-7.771-.646-11.639-1.129v-36c4.374-.875 8.22-1.1 12.625-1.027l4.55.041 2.389.026c18.306.171 36.124-.462 54.113-4.162 3.396-.678 6.03-1.126 9.323.122ZM72 220c.7 1.68.7 1.68 1 4-1.293 2.445-1.293 2.445-3.188 5.125C60.42 243.326 58.725 257.517 62 274c.388 2.997.694 5.993 1 9-14.102-1.995-30.507-22.95-38.66-33.676-2.403-3.326-4.508-6.646-6.34-10.324 1-3 1-3 4.367-4.832 1.496-.675 3-1.334 4.508-1.98l2.422-1.056C42.257 225.624 57.687 219.247 72 220Z"
        fill="#8400FF"
      />
      <path
        d="m193 273 39 1c-1.626 6.505-7.536 9.55-13 13-11.578 6.696-24.5 13.314-38 14 .633-3.33 1.888-5.562 3.75-8.375 4.125-6.75 4.125-6.75 8.25-19.625Z"
        fill="#8300FF"
      />
    </svg>
  );
}
